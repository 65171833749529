import * as React from 'react'
import Layout from '../../../components/Layout'
import LinkBar from '../../../components/LinkBar'
import BlockLinkFull from "../../../components/BlockLink/BlockLinkFull";
import TopImageHeading from "../../../components/TopImageHeading/TopImageHeading";

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet
import {
    middleCardImages,
    middleCardText,
    middleCardImage,
    middleCardWrapper
} from "../../../components/styles/titlepage.module.css";
import {
    topImageSectionImage, blockLinkBox
} from "../../../components/styles/mainpages.module.css"

//Global stylesheet
import "../../../components/styles/global.css"
import BlockLinkPartial from "../../../components/BlockLink/BlockLinkPartial";

const bangbiangyang = () => {
    return(
        <Layout pageTitle={"destinations"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Bangbiangyang | Ministry of Tourism Official Website</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="Some cities never sleep, others have slept themselves out of modern memory. In Bangbiangyang, you can experience
                both the pinnacle of modernity and the wastes of years past - find out more at the official site for Tourism Bangbiangyang."/>

                <meta property="og:title" content="Bangbiangyang | Ministry of Tourism Official Website"/>
                <meta property="og:description" content="Some cities never sleep, others have slept themselves out of modern memory. In Bangbiangyang, you can experience
                both the pinnacle of modernity and the wastes of years past - find out more at the official site for Tourism Bangbiangyang."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/destinations/bangbiangyang"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../../../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="/destinations">Places to go</Link> > <Link to="/destinations/bangbiangyang">Bangbiangyang</Link>
            </LinkBar>

            <TopImageHeading title="Bangbiangyang">
                <StaticImage className={topImageSectionImage} src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/USAID_Measuring_Impact_Conservation_Enterprise_Retrospective_%28Guatemala%3B_Rainforest_Alliance%29_%2840304337721%29.jpg/640px-USAID_Measuring_Impact_Conservation_Enterprise_Retrospective_%28Guatemala%3B_Rainforest_Alliance%29_%2840304337721%29.jpg" alt="."/>
            </TopImageHeading>

            <div className="midContentWrapper">
                <div className={middleCardWrapper}>
                    <div className={middleCardText}>
                        <h5 data-sal="slide-up">
                            For thousands of years, the city of Bangbiangyang has stood proudly on the shores of our largest island.
                        </h5>
                        <p data-sal="slide-up">
                            Just as you start to think your flight might never end, a paradise reveals itself beneath your feet. Dip below the clouds into
                            Bangbiangyang: an oasis of life, excitement, and adventure.
                            From a wintry mountain peak isolated in eternal fog to a golden coastline that's never blinded from the sun, the archipelago of Bangbiangyang
                            is home to an environment so diverse, you'd find nothing else like it.
                        </p>

                    </div>
                </div>
                <div className={middleCardImage}>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>

                </div>
            </div>

        </Layout>
    )
}

export default bangbiangyang